<template>
  <div>
    <h1>APPROVALS DASHBOARD</h1>
    <p v-if="approvals.length > 0">
      <button class="btn btn-success float-right" style="margin: 0 10px 0 10px;" @click="approveSelected">Approve</button>
      <button class="btn btn-danger float-right" @click='approveSelected("Rejected")'>Reject</button>
      <button class="btn btn-secondary float-right" style="margin: 0 10px 0 10px;" @click="loadEnvironment">Refresh</button>
    </p>
    <p>&nbsp;.</p>

    <table class="table table-striped table-hover table-sm text-left" v-if="approvals.length > 0">
      <thead class="thead-dark">
      <tr>
        <th><input style="margin: 5px;" type="checkbox" v-model="selectAll"></th>
        <th v-for="(column, columnKey) in columns" v-bind:key="columnKey">{{column.label}}</th>
        <th>&nbsp;</th>
      </tr>
      </thead>
      <tbody>

      <tr class="align-middle" v-for="(approval,approvalKey) in approvals" v-bind:key="approvalKey" style="height: 50px;">
        <td><input style="margin: 5px;" type="checkbox" v-model="selected" :value="approval.workflow"></td>
        <td>


          <div class="text-nowrap">
            <span v-if='approval.workflow.substring(0,6) === "HR-LAP"' @click="loadLeave(approval.workflow)" data-toggle="modal" data-target="#leaves" style="cursor: pointer; text-decoration: underline;">{{approval.workflow}}</span>
            <span v-else-if='approval.workflow.substring(0,6) === "HR-EXP"' @click="loadExpense(approval.workflow)" data-toggle="modal" data-target="#expense" style="cursor: pointer; text-decoration: underline;">{{approval.workflow}}</span>
            <span v-else-if='approval.workflow.substring(0,6) === "Emp-GR"' @click="loadGeneral(approval.workflow)" data-toggle="modal" data-target="#general" style="cursor: pointer; text-decoration: underline;">{{approval.workflow}}</span>
            <span v-else-if='approval.workflow.substring(0,6) === "PUR-OR" || approval.workflow.substring(0,2) === "PO"' @click="loadPO(approval.workflow)" data-toggle="modal" data-target="#po" style="cursor: pointer; text-decoration: underline;">{{approval.workflow}}</span>
            <span v-else-if='approval.workflow.substring(0,6) === "ACC-LO"' @click="loadLoans(approval.workflow)" data-toggle="modal" data-target="#loan" style="cursor: pointer; text-decoration: underline;">{{approval.workflow}}</span>
            <span v-else-if='approval.workflow.substring(0,6) === "MAT-MR"' @click="loadMaterial(approval.workflow)" data-toggle="modal" data-target="#material" style="cursor: pointer; text-decoration: underline;">{{approval.workflow}}</span>
            <span v-else-if='approval.workflow.substring(0,6) === "EMP-SE"'><a target="_blank" style="color: #333333;" :href="'http://system.alhamdaan.com/desk#Form/Resignation%20and%20Termination/'+approval.workflow">{{approval.workflow}}</a> </span>
            <span v-else>{{approval.workflow}}</span>
          </div>
        </td>

        <td>

          <div class="text-nowrap" style="font-weight: bolder;">
            <span v-if="approval.type === 'P.O'" style="color: #7a3d11;"><i class="fas fa-file-alt"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Payments'" style="color: #400003;"><i class="fas fa-credit-card"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Invoice'" style="color: #891240;"><i class="fas fa-file-invoice-dollar"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Leave'" style="color: royalblue;"><i class="fas fa-sign-out-alt"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Loan'" style="color: #9c9229;"><i class="fas fa-hand-holding-usd"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'General'" style="color: #7e237c;"><i class="fas fa-file-alt"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Overtime'"><i class="fas fa-clock"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Expense'" style="color: #a75310;"><i class="fas fa-money-bill-alt"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Material'"><i class="fas fa-box-open"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Visitor'"><i class="fas fa-door-open"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Temp Invoice'"><i class="fas fa-file-invoice-dollar"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'CAR'"><i class="fas fa-handshake"></i> {{ approval.type}}</span>
            <span v-if="approval.type === 'Termination'"><i class="fas fa-door-open"></i> {{ approval.type}}</span>

          </div>
        </td>

        <td>
          <div class="text-nowrap" style="font-size: 12px;">
            {{approval['c.c.']}}
          </div>
        </td>

        <td>
          <div class="text-nowrap" data-toggle="created" data-placement="top" :title='$moment(approval.created).fromNow()'>{{approval.created | moment("DD/MM/YYYY hh:mm a")}}</div>
          <!--          {{ result | moment("DD/MM/YYYY hh:mm a") }}-->
        </td>

        <td>
          <div class="text-nowrap">{{approval['initiator']}}</div>
        </td>

        <td>
          <div class="text-nowrap">{{approval['last_approver']}}</div>
        </td>

        <td>
          <div><i class="fas fa-info-circle" data-toggle="tooltip1" data-placement="top" :title="approval.status"></i></div>
        </td>

        <td>
          <div>{{approval['summary_/_related_information']}}</div>
        </td>

        <td>
          <div class="text-nowrap" data-toggle="updated" data-placement="top" :title='approval.updated | moment("DD/MM/YYYY hh:mm a")'>{{$moment(approval.updated).fromNow()}}</div>
        </td>

        <td>
          <div class="text-nowrap ">
            <span style="width: 40%; padding: 3px 3px 3px 6px; margin: 5px; color: white; background-color: #28a745; cursor: pointer;" @click="approveSingleSingle(approval.workflow,'Approve')"><i class="fas fa-check"></i></span>
            <span style="width: 40%; padding: 3px 3px 3px 6px; margin: 5px; color: white; background-color: #dc3545; cursor: pointer;" @click="approveSingleSingle(approval.workflow,'Rejected')"><i class="fas fa-times"></i></span>
          </div>
          </td>
      </tr>
      </tbody>
    </table>

    <div class="row" v-if="approvals.length === 0">
      <div class="col-md-12 text-center">
        <span style="font-size: 196px;"><i class="fas fa-mug-hot"></i></span>
        <h1 align="center" style="margin-left: -50px;">Relax!</h1>
        <h3 style="margin-left: -30px;">No more approvals to do..</h3>

      </div>
    </div>

<!--    =============================-->
<!--    Leave Application Modal Popup-->
<!--    =============================-->
    <div class="modal fade bd-example-modal-lg text-left" id="leaves" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel">Leave Application</h3>
            <a :href="'http://system.alhamdaan.com/desk#Form/Leave%20Application/'+leave.name" target="_blank" style="margin: 0 0 0 10px;">
              <button type="button" class="btn btn-primary">
                <i class="fas fa-sign-out-alt"></i>
              </button>
            </a>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Applicant Name:</strong></div>
                  <div class="col-md-8">{{leave.employee_name}}</div>
                  <div class="col-md-4"><strong>Department:</strong></div>
                  <div class="col-md-8">{{leave.department}}</div>
                  <div class="col-md-4"><strong>Nationality:</strong></div>
                  <div class="col-md-8">{{leave.nationality}}</div>
                  <div class="col-md-4"><strong>Contract Duration:</strong></div>
                  <div class="col-md-8">{{leave.contract_duration}} Year(s)</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Leave Type:</strong></div>
                  <div class="col-md-8">{{leave.leave_type}}</div>
                  <div class="col-md-4"><strong>From Date:</strong></div>
                  <div class="col-md-8">{{leave.from_date | moment("ddd, DD/MM/YYYY")}}</div>
                  <div class="col-md-4"><strong>To Date:</strong></div>
                  <div class="col-md-8">{{leave.to_date | moment("ddd, DD/MM/YYYY")}}</div>
                  <div class="col-md-4"><strong>Total Days:</strong></div>
                  <div class="col-md-8">{{leave.total_leave_days}} Days</div>
                </div>
              </div>
              <div class="col-md-12">
                <hr/>
                <div class="row">
                  <div class="col-md-2"><strong>Reason:</strong></div>
                  <div class="col-md-10">{{leave.description}}</div>
                </div>
              </div>
            </div>
            <hr/>

            <div class="row" v-if="attachments.length > 0">
              <div class="col-md-4" v-for="(attachment,attachmentKey) in attachments" v-bind:key="attachmentKey">
                <i class="fas fa-file-download"></i> &nbsp; <span v-html="attachment.content.replace(/href='/gm, replace)"></span> <br/>by {{attachment.comment_email}} ({{$moment(attachment.modified).fromNow()}})
              </div>
            </div>
            <hr/><br/>

            <table class="table table-striped table-hover table-sm text-left">
              <thead class="thead-dark">
              <tr>
                <th>Workflow Date</th>
                <th>Action User</th>
                <th>Action Details</th>
              </tr>
              </thead>
              <tbody>

              <tr class="align-middle" v-for="(workflow,workflowKey) in workflows" v-bind:key="workflowKey" style="height: 50px;">
                <td>{{workflow.modified | moment("ddd, DD/MM/YYYY")}}</td>
                <td>{{workflow.comment_email}}</td>
                <td>{{workflow.content}} ({{$moment(workflow.modified).fromNow()}})</td>
              </tr>
              </tbody>
            </table>

            <hr/><br/>

            <div v-if="comments.length > 0">
              <strong>Comments:</strong><br/>
              <div v-for="(comment,commentKey) in comments" v-bind:key="commentKey" style="border: 1px solid gray; padding: 10px; margin: 10px;">
                <span style="font-size: 14px;" v-html="replaceURL(comment.content.replace(/href='/gm, replace))"></span>
                <p style="margin-top: 11px; margin-bottom: -11px; font-size: 14px;" class="text-right">Commented By:  <strong>{{comment.comment_email}}</strong> {{$moment(comment.modified).fromNow()}}</p>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success float-right" data-dismiss="modal" aria-label="Close" style="margin: 0 10px 0 10px;" @click="approveSingle(leave.name)">Approve</button>
            <button class="btn btn-danger float-right" data-dismiss="modal" aria-label="Close" @click='approveSingle(leave.name,"Rejected")'>Reject</button>
          </div>
        </div>
      </div>
    </div>



    <!--    =============================-->
    <!--    Expense Claim Modal Popup    -->
    <!--    =============================-->
    <div class="modal fade bd-example-modal-lg text-left" id="expense" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="expenses">Expense Claim</h3>
            <a :href="'http://system.alhamdaan.com/desk#Form/Expense%20Claim/'+expense.name" target="_blank" style="margin: 0 0 0 10px;">
              <button type="button" class="btn btn-primary">
                <i class="fas fa-sign-out-alt"></i>
              </button>
            </a>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Applicant Name:</strong></div>
                  <div class="col-md-8">{{expense.employee_name}}</div>
                  <div class="col-md-4"><strong>Department:</strong></div>
                  <div class="col-md-8">{{expense.department}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Cost Center:</strong></div>
                  <div class="col-md-8">{{expense.cost_center}}</div>
                  <div class="col-md-4"><strong>Project:</strong></div>
                  <div class="col-md-8">{{expense.project}}</div>
                </div>
              </div>
              <div class="col-md-12">
                <hr/>

                <div class="row" style="margin: 20px;">
                  <h3>Expense Breakdown:</h3>


                  <table class="table table-striped table-hover table-sm text-left">
                    <thead class="thead-dark">
                    <tr>
                      <th>Expense Date</th>
                      <th>Expense Type</th>
                      <th>Cost Center</th>
                      <th>Total Amount</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr class="align-middle" v-for="(expenseItem,expenseItemKey) in expense.expenses" v-bind:key="expenseItemKey" style="height: 50px;">
                      <td>{{expenseItem.expense_date | moment("ddd, DD/MM/YYYY")}}</td>
                      <td>{{expenseItem.expense_type}}</td>
                      <td>{{expenseItem.cost_center}}</td>
                      <td>{{expenseItem.amount}} SAR</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="col-md-12">
                    <hr/>
                  </div>

                  <div class="col-md-10 text-right"><strong>GRAND TOTAL:</strong></div>
                  <div class="col-md-2 text-right">{{expense.grand_total}} SAR</div>
                </div>
              </div>
            </div>
            <hr/>



            <div class="row" v-if="attachments.length > 0">
              <div class="col-md-4" v-for="(attachment,attachmentKey) in attachments" v-bind:key="attachmentKey">
                <i class="fas fa-file-download"></i> &nbsp; <span v-html="attachment.content.replace(/href='/gm, replace)"></span> <br/> <span style="margin-right: 25px;"></span> by {{attachment.comment_email}} ({{$moment(attachment.modified).fromNow()}})
              </div>
            </div>
            <hr/><br/>

            <table class="table table-striped table-hover table-sm text-left">
              <thead class="thead-dark">
              <tr>
                <th>Workflow Date</th>
                <th>Action User</th>
                <th>Action Details</th>
              </tr>
              </thead>
              <tbody>

              <tr class="align-middle" v-for="(workflow,workflowKey) in workflows" v-bind:key="workflowKey" style="height: 50px;">
                <td>{{workflow.modified | moment("ddd, DD/MM/YYYY")}}</td>
                <td>{{workflow.comment_email}}</td>
                <td>{{workflow.content}} ({{$moment(workflow.modified).fromNow()}})</td>
              </tr>
              </tbody>
            </table>

            <hr/><br/>

            <div v-if="comments.length > 0">
              <strong>Comments:</strong><br/>
              <div v-for="(comment,commentKey) in comments" v-bind:key="commentKey" style="border: 1px solid gray; padding: 10px; margin: 10px;">
                <span style="font-size: 14px;" v-html="replaceURL(comment.content.replace(/href='/gm, replace))"></span>
                <p style="margin-top: 11px; margin-bottom: -11px; font-size: 14px;" class="text-right">Commented By:  <strong>{{comment.comment_email}}</strong> {{$moment(comment.modified).fromNow()}}</p>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success float-right" data-dismiss="modal" aria-label="Close" style="margin: 0 10px 0 10px;" @click="approveSingle(expense.name)">Approve</button>
            <button class="btn btn-danger float-right" data-dismiss="modal" aria-label="Close" @click='approveSingle(expense.name,"Rejected")'>Reject</button>
          </div>
        </div>
      </div>
    </div>



    <!--    =============================-->
    <!--    General Application Modal Popup-->
    <!--    =============================-->
    <div class="modal fade bd-example-modal-lg text-left" id="general" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel1">General Request</h3>
            <a :href="'http://system.alhamdaan.com/desk#Form/Employee%20General%20Request%20Form/'+general.name" target="_blank" style="margin: 0 0 0 10px;">
              <button type="button" class="btn btn-primary">
                <i class="fas fa-sign-out-alt"></i>
              </button>
            </a>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Applicant Name:</strong></div>
                  <div class="col-md-8">{{general.employee_name}}</div>
                  <div class="col-md-4"><strong>Department:</strong></div>
                  <div class="col-md-8">{{general.department}}</div>
                  <div class="col-md-4"><strong>Joining Date:</strong></div>
                  <div class="col-md-8">{{general.joining_date | moment("ddd, DD/MM/YYYY")}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Request Date:</strong></div>
                  <div class="col-md-8">{{general.creation}}</div>
                  <div class="col-md-4"><strong>Request Type:</strong></div>
                  <div class="col-md-8">{{general.request_type}}</div>
                  <div class="col-md-4"><strong>Required By Date:</strong></div>
                  <div class="col-md-8">{{general.required_date | moment("ddd, DD/MM/YYYY")}}</div>
                </div>
              </div>
              <div class="col-md-12">
                <hr/>
                <div class="row">
                  <div class="col-md-12"><strong>{{general.summary}}</strong></div>
                  <div v-if="general.description" class="col-md-12" v-html="replaceURL(general.description.replace(/href='/gm, replace))"></div>
                </div>
              </div>
            </div>
            <hr/>

            <div class="row" v-if="attachments.length > 0">
              <div class="col-md-4" v-for="(attachment,attachmentKey) in attachments" v-bind:key="attachmentKey">
                <i class="fas fa-file-download"></i> &nbsp; <span v-html="attachment.content.replace(/href='/gm, replace)"></span> <br/>by {{attachment.comment_email}} ({{$moment(attachment.modified).fromNow()}})
              </div>
            </div>
            <hr/><br/>

            <table class="table table-striped table-hover table-sm text-left">
              <thead class="thead-dark">
              <tr>
                <th>Workflow Date</th>
                <th>Action User</th>
                <th>Action Details</th>
              </tr>
              </thead>
              <tbody>

              <tr class="align-middle" v-for="(workflow,workflowKey) in workflows" v-bind:key="workflowKey" style="height: 50px;">
                <td>{{workflow.modified | moment("ddd, DD/MM/YYYY")}}</td>
                <td>{{workflow.comment_email}}</td>
                <td>{{workflow.content}} ({{$moment(workflow.modified).fromNow()}})</td>
              </tr>
              </tbody>
            </table>

            <hr/><br/>

            <div v-if="comments.length > 0">
              <strong>Comments:</strong><br/>
              <div v-for="(comment,commentKey) in comments" v-bind:key="commentKey" style="border: 1px solid gray; padding: 10px; margin: 10px;">
                <span style="font-size: 14px;" v-html="replaceURL(comment.content.replace(/href='/gm, replace))"></span>
                <p style="margin-top: 11px; margin-bottom: -11px; font-size: 14px;" class="text-right">Commented By:  <strong>{{comment.comment_email}}</strong> {{$moment(comment.modified).fromNow()}}</p>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success float-right" data-dismiss="modal" aria-label="Close" style="margin: 0 10px 0 10px;" @click="approveSingle(general.name)">Approve</button>
            <button class="btn btn-danger float-right" data-dismiss="modal" aria-label="Close" @click='approveSingle(general.name,"Rejected")'>Reject</button>
          </div>
        </div>
      </div>
    </div>



    <!--    =============================-->
    <!--    Loans Application Modal Popup-->
    <!--    =============================-->
    <div class="modal fade bd-example-modal-lg text-left" id="loan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLabel11">Loan Request</h3>
            <a :href="'http://system.alhamdaan.com/desk#Form/Loan%20Application/'+loan.name" target="_blank" style="margin: 0 0 0 10px;">
              <button type="button" class="btn btn-primary">
                <i class="fas fa-sign-out-alt"></i>
              </button>
            </a>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Applicant Name:</strong></div>
                  <div class="col-md-8">{{loan.applicant_name}}</div>
                  <div class="col-md-4"><strong>Department:</strong></div>
                  <div class="col-md-8">{{loan.department}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Loan Type:</strong></div>
                  <div class="col-md-8">{{loan.loan_type}}</div>
                  <div class="col-md-4"><strong>Requested Amount:</strong></div>
                  <div class="col-md-8">{{loan.loan_amount}}</div>
                </div>
              </div>
              <div class="col-md-12">
                <hr/>
                <div class="row">
                  <div class="col-md-12"><strong>{{loan.summary}}</strong></div>
                  <div v-if="loan.description" class="col-md-12" v-html="replaceURL(loan.description.replace(/href='/gm, replace))"></div>
                </div>
              </div>
            </div>
            <hr/>

            <div class="row" v-if="attachments.length > 0">
              <div class="col-md-4" v-for="(attachment,attachmentKey) in attachments" v-bind:key="attachmentKey">
                <i class="fas fa-file-download"></i> &nbsp; <span v-html="attachment.content.replace(/href='/gm, replace)"></span> <br/>by {{attachment.comment_email}} ({{$moment(attachment.modified).fromNow()}})
              </div>
            </div>
            <hr/><br/>

            <table class="table table-striped table-hover table-sm text-left">
              <thead class="thead-dark">
              <tr>
                <th>Workflow Date</th>
                <th>Action User</th>
                <th>Action Details</th>
              </tr>
              </thead>
              <tbody>

              <tr class="align-middle" v-for="(workflow,workflowKey) in workflows" v-bind:key="workflowKey" style="height: 50px;">
                <td>{{workflow.modified | moment("ddd, DD/MM/YYYY")}}</td>
                <td>{{workflow.comment_email}}</td>
                <td>{{workflow.content}} ({{$moment(workflow.modified).fromNow()}})</td>
              </tr>
              </tbody>
            </table>

            <hr/><br/>

            <div v-if="comments.length > 0">
              <strong>Comments:</strong><br/>
              <div v-for="(comment,commentKey) in comments" v-bind:key="commentKey" style="border: 1px solid gray; padding: 10px; margin: 10px;">
                <span style="font-size: 14px;" v-html="replaceURL(comment.content.replace(/href='/gm, replace))"></span>
                <p style="margin-top: 11px; margin-bottom: -11px; font-size: 14px;" class="text-right">Commented By:  <strong>{{comment.comment_email}}</strong> {{$moment(comment.modified).fromNow()}}</p>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-success float-right" data-dismiss="modal" aria-label="Close" style="margin: 0 10px 0 10px;" @click="approveSingle(loan.name)">Approve</button>
            <button class="btn btn-danger float-right" data-dismiss="modal" aria-label="Close" @click='approveSingle(loan.name,"Rejected")'>Reject</button>
          </div>
        </div>
      </div>
    </div>


    <!--    =============================-->
    <!--    Purchase Order Modal Popup    -->
    <!--    =============================-->
    <div class="modal fade bd-example-modal-lg text-left" id="po" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="expenses1">Purchase Order</h3>
            <a :href="'http://system.alhamdaan.com/desk#Form/Purchase%20Order/'+po.name" target="_blank" style="margin: 0 0 0 10px;">
              <button type="button" class="btn btn-primary">
                <i class="fas fa-sign-out-alt"></i>
              </button>
            </a>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Supplier Name:</strong></div>
                  <div class="col-md-8">{{po.supplier_name}}</div>
                  <div class="col-md-4"><strong>Buyer Department:</strong></div>
                  <div class="col-md-8">{{po.department}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Required By:</strong></div>
                  <div class="col-md-8">{{po.schedule_date}}</div>
                  <div class="col-md-4"><strong>Payment Terms:</strong></div>
                  <div class="col-md-8">{{po.payment_terms_template}}</div>
                </div>
              </div>
              <div class="col-md-12">
                <hr/>

                <div class="row" style="margin: 20px;">
                  <h3>PO Items List:</h3>


                  <table class="table table-striped table-hover table-sm text-left">
                    <thead class="thead-dark">
                    <tr>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Unit Price</th>
                      <th>Material Request</th>
                      <th>Cost Center</th>
                      <th>Project</th>
                      <th>Total Amount</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr class="align-middle" v-for="(item,itemKey) in po.items" v-bind:key="itemKey" style="height: 50px;">
                      <td>{{item.item_name}}</td>
                      <td class="text-nowrap">{{item.qty}}</td>
                      <td class="text-nowrap">{{item.uom}}</td>
                      <td class="text-nowrap">{{item.rate}} SAR</td>
                      <td class="text-nowrap"><a v-if="item.material_request" target="_blank" :href="'http://system.alhamdaan.com/desk#Form/Material%20Request/'+item.material_request">{{item.material_request}}</a></td>
                      <td class="text-nowrap">{{item.cost_center}}</td>
                      <td class="text-nowrap">{{item.project}}</td>
                      <td class="text-nowrap">{{item.net_amount}} SAR</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="col-md-12">
                    <hr/>
                  </div>

                  <div class="col-md-10 text-right"><strong>GRAND TOTAL:</strong></div>
                  <div class="col-md-2 text-right">{{po.net_total}} {{po.price_list_currency}}</div>
                </div>
              </div>
            </div>
            <hr/>



            <div class="row" v-if="attachments.length > 0">
              <div class="col-md-4" v-for="(attachment,attachmentKey) in attachments" v-bind:key="attachmentKey">
                <i class="fas fa-file-download"></i> &nbsp; <span v-html="attachment.content.replace(/href='/gm, replace)"></span> <br/> <span style="margin-right: 25px;"></span> by {{attachment.comment_email}} ({{$moment(attachment.modified).fromNow()}})
              </div>
            </div>
            <hr/><br/>

            <table class="table table-striped table-hover table-sm text-left">
              <thead class="thead-dark">
              <tr>
                <th>Workflow Date</th>
                <th>Action User</th>
                <th>Action Details</th>
              </tr>
              </thead>
              <tbody>

              <tr class="align-middle" v-for="(workflow,workflowKey) in workflows" v-bind:key="workflowKey" style="height: 50px;">
                <td>{{workflow.modified | moment("ddd, DD/MM/YYYY")}}</td>
                <td>{{workflow.comment_email}}</td>
                <td>{{workflow.content}} ({{$moment(workflow.modified).fromNow()}})</td>
              </tr>
              </tbody>
            </table>

            <hr/><br/>

            <div v-if="comments.length > 0">
              <strong>Comments:</strong><br/>
              <div v-for="(comment,commentKey) in comments" v-bind:key="commentKey" style="border: 1px solid gray; padding: 10px; margin: 10px;">
                <span style="font-size: 14px;" v-html="replaceURL(comment.content.replace(/href='/gm, replace))"></span>
                <p style="margin-top: 11px; margin-bottom: -11px; font-size: 14px;" class="text-right">Commented By:  <strong>{{comment.comment_email}}</strong> {{$moment(comment.modified).fromNow()}}</p>

              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button class="btn btn-success float-right" data-dismiss="modal" aria-label="Close" style="margin: 0 10px 0 10px;" @click="approveSingle(po.name)">Approve</button>
            <button class="btn btn-danger float-right" data-dismiss="modal" aria-label="Close" @click='approveSingle(po.name,"Rejected")'>Reject</button>
          </div>
        </div>
      </div>
    </div>


    <!--    =============================-->
    <!--    Material Request Modal Popup    -->
    <!--    =============================-->
    <div class="modal fade bd-example-modal-lg text-left" id="material" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title" id="expenses22">Material Request</h3>
            <a :href="'http://system.alhamdaan.com/desk#Form/material-request/'+material.name" target="_blank" style="margin: 0 0 0 10px;">
              <button type="button" class="btn btn-primary">
                <i class="fas fa-sign-out-alt"></i>
              </button>
            </a>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Material Request Type:</strong></div>
                  <div class="col-md-8">{{material.material_request_type}}</div>
                  <div class="col-md-4"><strong>Buyer Department:</strong></div>
                  <div class="col-md-8">{{material.department}}</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-4"><strong>Required By:</strong></div>
                  <div class="col-md-8">{{material.schedule_date | moment("DD/MM/YYYY")}} ({{$moment(material.schedule_date).fromNow()}})</div>
                  <div class="col-md-4"><strong>Initiator:</strong></div>
                  <div class="col-md-8">{{material.owner}}</div>
                </div>
              </div>
              <div class="col-md-12">
                <hr/>
                <span v-html="material.description"></span>
                <hr/>

                <div class="row" style="margin: 20px;">
                  <h3>Material Request Items List:</h3>


                  <table class="table table-striped table-hover table-sm text-left">
                    <thead class="thead-dark">
                    <tr>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Unit</th>
                      <th>Cost Center</th>
                      <th>Project</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr class="align-middle" v-for="(item,itemKey) in material.items" v-bind:key="itemKey" style="height: 50px;">
                      <td>{{item.item_name}}</td>
                      <td class="text-nowrap">{{item.qty}}</td>
                      <td class="text-nowrap">{{item.uom}}</td>
                      <td class="text-nowrap">{{item.cost_center}}</td>
                      <td class="text-nowrap">{{item.project}}</td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>
              </div>
            </div>
            <hr/>



            <div class="row" v-if="attachments.length > 0">
              <div class="col-md-4" v-for="(attachment,attachmentKey) in attachments" v-bind:key="attachmentKey">
                <i class="fas fa-file-download"></i> &nbsp; <span v-html="attachment.content.replace(/href='/gm, replace)"></span> <br/> <span style="margin-right: 25px;"></span> by {{attachment.comment_email}} ({{$moment(attachment.modified).fromNow()}})
              </div>
            </div>
            <hr/><br/>

            <table class="table table-striped table-hover table-sm text-left">
              <thead class="thead-dark">
              <tr>
                <th>Workflow Date</th>
                <th>Action User</th>
                <th>Action Details</th>
              </tr>
              </thead>
              <tbody>

              <tr class="align-middle" v-for="(workflow,workflowKey) in workflows" v-bind:key="workflowKey" style="height: 50px;">
                <td>{{workflow.modified | moment("ddd, DD/MM/YYYY")}}</td>
                <td>{{workflow.comment_email}}</td>
                <td>{{workflow.content}} ({{$moment(workflow.modified).fromNow()}})</td>
              </tr>
              </tbody>
            </table>

            <hr/><br/>

            <div v-if="comments.length > 0">
              <strong>Comments:</strong><br/>
              <div v-for="(comment,commentKey) in comments" v-bind:key="commentKey" style="border: 1px solid gray; padding: 10px; margin: 10px;">
                <span style="font-size: 14px;" v-html="replaceURL(comment.content.replace(/href='/gm, replace))"></span>
                <p style="margin-top: 11px; margin-bottom: -11px; font-size: 14px;" class="text-right">Commented By:  <strong>{{comment.comment_email}}</strong> {{$moment(comment.modified).fromNow()}}</p>

              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button class="btn btn-success float-right" data-dismiss="modal" aria-label="Close" style="margin: 0 10px 0 10px;" @click="approveSingle(material.name)">Approve</button>
            <button class="btn btn-danger float-right" data-dismiss="modal" aria-label="Close" @click='approveSingle(material.name,"Rejected")'>Reject</button>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
    name: 'Home',
    components: {},

    data () {
        return {
            approvals: this.$store.getters['approvals/getApprovals'],
            columns: this.$store.getters['approvals/getColumns'],
            roles: this.$store.getters['profile/getRoles'],
            regex: '/"//gm',
            replace: "href='http://system.alhamdaan.com",
            selected: [],
            comments: [],
            workflows: [],
            attachments: [],
            leave: {},
            expense: {},
            general: {},
            po: {},
            loan: {},
            material: {},

        }
    },
    methods: {

        approveSelected(action = null){
            let variable = "Approve";
            if(action === "Rejected"){variable = "Reject";}
            if(confirm("Do you really want to "+variable+" selected records?")){
                if(this.selected.length > 0){
                    this.selected.forEach(function (item, index) {
                        if(action === "Rejected"){this.approveSingle(item,action);} else{this.approveSingle(item);}
                        console.log(index)
                    }.bind(this));
                } else{
                    alert('No records have been selected.');
                }
            }
            variable = "Approve";
        },

        removeProcessedFromIndex(item){
            let index = this.selected.indexOf(item);
            if (index !== -1) {
                this.selected.splice(index, 1);
            }
        },

        approveSingleSingle(item, action = null){
            if(confirm("Are you sure you want to update selected record?")){
                this.approveSingle(item, action);
            }
        },

        approveSingle(item, action = null){
            let workflowType = null;
            let endpoint = null;
            let nextState = null;
            let status = null;
            if(item.substring(0,6) === "PUR-OR" || item.substring(0,2) === "PO" ){
                workflowType = "Purchase Order";
                endpoint = "http://system.alhamdaan.com/api/resource/Purchase%20Order/"+item.replace("#", "%23");
                nextState = localStorage.getItem('PUR-OR');
                status = "Pending";
            }
            if(item.substring(0,6) === "ACC-PA"){
                workflowType = "Payment Entry";
                endpoint = "http://system.alhamdaan.com/api/resource/Payment%20Entry/"+item;
                nextState = localStorage.getItem('ACC-PA');
                status = "Pending";
            }
            if(item.substring(0,6) === "ACC-PI"){
                workflowType = "Purchase Invoice";
                endpoint = "http://system.alhamdaan.com/api/resource/Purchase%20Invoice/"+item;
                nextState = localStorage.getItem('ACC-PI');
                status = "Pending";
            }
            if(item.substring(0,6) === "HR-LAP"){
                workflowType = "Leave Application";
                endpoint = "http://system.alhamdaan.com/api/resource/Leave%20Application/"+item;
                nextState = localStorage.getItem('HR-LAP');
                status = "Pending";
            }
            if(item.substring(0,6) === "ACC-LO"){
                workflowType = "Loan Application";
                endpoint = "http://system.alhamdaan.com/api/resource/Loan%20Application/"+item;
                nextState = localStorage.getItem('ACC-LO');
                status = "Approved";
            }
            if(item.substring(0,6) === "Emp-GR"){
                workflowType = "Employee General Request Form";
                endpoint = "http://system.alhamdaan.com/api/resource/Employee%20General%20Request%20Form/"+item;
                nextState = localStorage.getItem('Emp-GR');
                status = "Pending";
            }
            if(item.substring(0,6) === "HR-ADS"){
                workflowType = "Additional Salary";
                endpoint = "http://system.alhamdaan.com/api/resource/Additional%20Salary/"+item;
                nextState = localStorage.getItem('HR-ADS');
                status = "Pending";
            }
            if(item.substring(0,6) === "HR-EXP"){
                workflowType = "Expense Claim";
                endpoint = "http://system.alhamdaan.com/api/resource/Expense%20Claim/"+item;
                nextState = localStorage.getItem('HR-EXP');
                status = "Pending";
            }
            if(item.substring(0,6) === "MAT-MR"){
                workflowType = "Material Request";
                endpoint = "http://system.alhamdaan.com/api/resource/Material Request/"+item;
                nextState = localStorage.getItem('MAT-MR');
                status = "Approved";
            }
            if(item.substring(0,6) === "Visito"){
                workflowType = "Visitor Management";
                endpoint = "http://system.alhamdaan.com/api/resource/Visitor%20Management/"+item;
                nextState = localStorage.getItem('Visito');
                status = "Pending";
            }
            if(item.substring(0,6) === "TIA-20"){
                workflowType = "Temporary Invoice Approval";
                endpoint = "http://system.alhamdaan.com/api/resource/Temporary%20Invoice%20Approval/"+item;
                nextState = localStorage.getItem('TIA-20');
                status = "Pending";
            }
            if(item.substring(0,6) === "AGR-20"){
                workflowType = "Commercial Agreement Registration  CAR";
                endpoint = "http://system.alhamdaan.com/api/resource/Commercial%20Agreement%20Registration%20%20CAR/"+item;
                nextState = localStorage.getItem('AGR-20');
                status = "Pending";
            }
            if(item.substring(0,6) === "EMP-SE"){
                workflowType = "Resignation and Termination";
                endpoint = "http://system.alhamdaan.com/api/resource/Resignation%20and%20Termination/"+item;
                nextState = localStorage.getItem('EMP-SE');
                status = "Pending";
            }

            if(action === "Rejected"){nextState = "Rejected"; status = "Rejected"}
            this.$store.dispatch('approvals/approve',{endURL: endpoint, stateValue: nextState, status: status, workflow: workflowType, workflowItem: item})
                .then(response => {
                    if(this.roles === 5){alert(response)}
                    this.loadEnvironment();
                    this.removeProcessedFromIndex(item);
                })
                .catch(error => {if(this.roles === 5){alert(JSON.stringify(error))}})
            console.log(workflowType)
        },

        replaceURL(data){
            data = data.replace('"/files', '"http://system.alhamdaan.com/files')
            data = data.replace('"/files/', '"http://system.alhamdaan.com/files/')
            data = data.replace('"/files', '"http://system.alhamdaan.com/files')
            data = data.replace('"/files/', '"http://system.alhamdaan.com/files/')
            data = data.replace('"/private', '"http://system.alhamdaan.com/private')
            data = data.replace('"/private/', '"http://system.alhamdaan.com/private/')
            data = data.replace('"/private', '"http://system.alhamdaan.com/private')
            data = data.replace('"/private/', '"http://system.alhamdaan.com/private/')
            return data;
        },

        getCommentsAttachmentsWorkflow(leaveID){
            this.$store.dispatch('approvals/fetchComments',leaveID)
                .then(response => {
                    this.comments = response;
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.comments = [];
                })
            this.$store.dispatch('approvals/fetchWorkflow',leaveID)
                .then(response => {
                    this.workflows = response;
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.workflow = [];
                })
            this.$store.dispatch('approvals/fetchAttachments',leaveID)
                .then(response => {
                    this.attachments = response;
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.attachments = [];
                })
        },

        loadLeave(leaveID){
            this.$store.dispatch('leaves/fetchLeaves',leaveID)
                .then(response => {
                    this.leave = response;
                    this.getCommentsAttachmentsWorkflow(leaveID);
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.leave = {};
                })
        },


        loadExpense(leaveID){
            this.$store.dispatch('expense/fetchExpense',leaveID)
                .then(response => {
                    this.expense = response;
                    this.getCommentsAttachmentsWorkflow(leaveID);
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.expense = {};
                })
        },

        loadGeneral(leaveID){
            this.$store.dispatch('general/fetchGeneral',leaveID)
                .then(response => {
                    this.general = response;
                    this.getCommentsAttachmentsWorkflow(leaveID);
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.general = {};
                })
        },

        loadPO(leaveID){
          leaveID =leaveID.replace("#", "%23")
          this.$store.dispatch('po/fetchPOs',leaveID)
                .then(response => {
                    this.po = response;
                    this.getCommentsAttachmentsWorkflow(leaveID);
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.po = {};
                })
        },

        loadLoans(leaveID){
            this.$store.dispatch('loan/fetchLoans',leaveID)
                .then(response => {
                    this.loan = response;
                    this.getCommentsAttachmentsWorkflow(leaveID);
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.loans = {};
                })
        },

        loadMaterial(leaveID){
            this.$store.dispatch('material/fetchMaterial',leaveID)
                .then(response => {
                    this.material = response;
                    this.getCommentsAttachmentsWorkflow(leaveID);
                })
                .catch(error => {
                    if(error){console.log(error)}
                    this.material = {};
                })
        },

        loadEnvironment() {
            this.$store.dispatch('approvals/fetchApprovals')
                .then(response => {
                    if(this.roles === 5){alert(response)}
                    this.approvals = this.$store.getters['approvals/getApprovals'];
                    this.columns = this.$store.getters['approvals/getColumns'];
                    this.comments = [];
                })
                .catch(error => {
                    if(this.roles === 5){alert(error)}
                })

        }
    },

    computed: {
        selectAll: {
            get: function () {
                return this.approvals ? this.selected.length == this.approvals.length : false;
            },
            set: function (value) {
                let selected = [];

                if (value) {
                    this.approvals.forEach(function (record) {
                        selected.push(record[0]);
                    });
                }

                this.selected = selected;
            }
        }
    },

    mounted(){
        this.loadEnvironment();
    },
    created() {

    }


}
</script>
