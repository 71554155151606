import axios from "axios";
import router from "../../router";

const namespaced = true;

const state = {
    // comments: [],
    // leave: {},
};

const getters = {
    // getLeave (state) {
    //     return state.leave
    // },
};

const mutations = {
    // setLeave (state, payload) {
    //     state.leave = payload
    // },
    // setComments (state, payload) {
    //     state.comments = payload
    // },
};

const actions = {
    fetchPOs ({commit, dispatch}, leaveID) {
        if(commit === dispatch || commit === router){console.log("Test")}
        return new Promise((resolve, reject) => {
            axios.get("http://system.alhamdaan.com/api/resource/Purchase%20Order/"+leaveID)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },


};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
