import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './assets/main.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'zingchart/es6';
import zingchartVue from 'zingchart-vue';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(require('vue-moment'));

Vue.component('zingchart', zingchartVue)


const reqInterceptor = axios.interceptors.request.use(config => {
  console.log('Request Interceptor', config);
  return config
});
const resInterceptor = axios.interceptors.response.use(res => {
  console.log('Response Interceptor', res);
  return res
});
axios.interceptors.request.eject(reqInterceptor);
axios.interceptors.response.eject(resInterceptor);
// axios.defaults.baseURL = 'http://system.alhamdaan.com';

// axios.defaults.headers.get['Access-Control-Allow-Origin'] = 'http://localhost:8080';
// axios.defaults.headers.get['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers.get['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
// axios.defaults.headers.get['Cookie'] = "system_user=yes; user_image=; sid=22e5e21b6785fcd0d055e1ff0bf9ced62d5f75f3c5be6686e6727fd3; full_name=Administrator; user_id=Administrator; io=XzCzScz2mNpNh-BMAAB-";
// axios.defaults.headers.get['Cookie'] = document.cookie;
// axios.defaults.headers.get['Accepts'] = 'application/json';
// axios.defaults.headers.get['X-Frappe-CSRF-Token'] = '2de8c4abc422b8d';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');


// axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:8080';
// axios.defaults.headers.post['Access-Control-Allow-Credentials'] = true;
// axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
// axios.defaults.headers.post['Accepts'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.post['X-Frappe-CSRF-Token'] = 'application/json';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');

// axios.defaults.headers.put['Accepts'] = 'application/json';
// axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.put['X-Frappe-CSRF-Token'] = 'application/json';
// axios.defaults.headers.put['Access-Control-Allow-Origin'] = 'http://localhost:8080/';
axios.defaults.headers.put['Authorization'] = localStorage.getItem('token');

// axios.defaults.headers.delete['Content-type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.delete['Accepts'] = 'application/json';
// axios.defaults.headers.delete['X-Frappe-CSRF-Token'] = 'application/json';
// axios.defaults.headers.delete['Access-Control-Allow-Origin'] = '{{ frappe.session.csrf_token }}';
axios.defaults.headers.delete['Authorization'] = localStorage.getItem('token');

router.beforeEach((to, from, next) => {
  if(to.path === '/login' || to.path === '/logout') {next(); }
  else{
    if(localStorage.getItem('token') !== null && localStorage.getItem('token') !=='undefined') {next();}
    else {const path = '/login?redirect=' + from.path; next(path);}
  }
});

new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app');
