import axios from "axios";
import router from "../../router";

const namespaced = true;

const state = {
    approvals: [],
    columns: [],
};

const getters = {
    getApprovals (state) {
        return state.approvals
    },
    getColumns (state) {
        return state.columns
    },
};

const mutations = {
    setApprovals (state, payload) {
        state.approvals = payload
    },
    setColumns (state, payload) {
        state.columns = payload
    },
};

const actions = {
    fetchApprovals ({commit, dispatch}) {
        if(commit === dispatch || commit === router){console.log("Test")}
        return new Promise((resolve, reject) => {
            let report = localStorage.getItem('report')
            axios.get('http://system.alhamdaan.com/api/method/frappe.desk.query_report.run?report_name='+report)
                .then(res => {
                    commit('setApprovals', res.data.message.result);
                    commit('setColumns', res.data.message.columns);
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },

    approve ({commit, dispatch}, workflowData) {
        if(commit === dispatch){console.log("Test")}

        return new Promise((resolve, reject) => {
            // axios.get('http://system.alhamdaan.com/api/method/logout');
            axios.put(workflowData.endURL, {
                'workflow_state': workflowData.stateValue,
                'status': workflowData.status,
            })
                .then(res => {
                    resolve(res.data);
                    axios.post('http://system.alhamdaan.com/api/resource/Comment', {
                        'comment_type': 'Workflow',
                        'reference_doctype': workflowData.workflow,
                        'reference_name': workflowData.workflowItem,
                        'comment_email': localStorage.getItem('username'),
                        'content': workflowData.stateValue,
                    })
                })
                .catch(error => {
                    alert(JSON.stringify(error))
                    reject(error.response);
                })
        })
    },


    fetchComments ({commit, dispatch}, leaveID) {
        if(commit === dispatch || commit === router){console.log("Test")}
        return new Promise((resolve, reject) => {
            axios.get('http://system.alhamdaan.com/api/resource/Comment?fields=["name","comment_type","reference_name","comment_email","content","modified"]&filters=[["comment_type", "=", "Comment"], ["reference_name", "=", "'+leaveID+'"]]')
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },

    fetchWorkflow ({commit, dispatch}, leaveID) {
        if(commit === dispatch || commit === router){console.log("Test")}
        return new Promise((resolve, reject) => {
            axios.get('http://system.alhamdaan.com/api/resource/Comment?fields=["name","comment_type","reference_name","comment_email","content","modified"]&filters=[["comment_type", "=", "Workflow"], ["reference_name", "=", "'+leaveID+'"]]')
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },

    fetchAttachments ({commit, dispatch}, leaveID) {
        if(commit === dispatch || commit === router){console.log("Test")}
        return new Promise((resolve, reject) => {
            axios.get('http://system.alhamdaan.com/api/resource/Comment?fields=["name","comment_type","reference_name","comment_email","content","modified"]&filters=[["comment_type", "=", "Attachment"], ["reference_name", "=", "'+leaveID+'"]]')
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
