import Vue from 'vue';
import Vuex from 'vuex';
import approvals from './workflow/approvals';
import profile from './user/profile';
import leaves from './hr/leaves';
import expense from './hr/expense';
import general from './hr/general';
import loan from './hr/loan';
import po from './hr/po';
import material from './buying/material';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    approvals, profile, leaves, expense, general, loan, po, material
  }
});
