import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/supply',
    name: 'Supply',
    component: () => import(/* webpackChunkName: "about" */ '../views/supply.vue')
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import(/* webpackChunkName: "about" */ '../views/finance.vue')
  },
  {
    path: '/hr',
    name: 'HR',
    component: () => import(/* webpackChunkName: "about" */ '../views/hr.vue')
  },
  {
    path: '/sales',
    name: 'Sales',
    component: () => import(/* webpackChunkName: "about" */ '../views/sales.vue')
  },
  {
    path: '/transport',
    name: 'Transport',
    component: () => import(/* webpackChunkName: "about" */ '../views/transport.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/dashboard',
    name: 'BI Dashboard',
    // route level code-splitting     // this generates a separate chunk (about.[hash].js) for this route  // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
