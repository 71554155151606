import axios from 'axios'
import router from './../../router'
const namespaced = true;

const state = {
    fullName: "",
    username: "",
    roles: [],
};

const getters = {
    getFullName (state) {return state.fullName},
    getUsername (state) {return state.username},
    getRoles (state) {return state.roles},
};

const mutations = {
    setFullName (state, payload) {state.fullName = payload},
    setUsername (state, payload) {state.username = payload},
    setRoles (state, payload) {state.roles = payload},
};

const actions = {

    login ({commit, dispatch}, authData) {
        if(commit === dispatch){console.log("Test")}
        let token = null;
        return new Promise((resolve, reject) => {
            axios.get('http://system.alhamdaan.com/api/method/login_api.api.login?usr='+authData.username+'&pwd='+authData.password)
                .then(res => {
                    commit('setUsername', authData.username);
                    token = "token "+res.data.message.api_key+":"+res.data.message.api_secret;
                    // alert(token);
                    localStorage.setItem('username', authData.username);
                    localStorage.setItem('token', token);
                    if(authData.username === "Administrator"){
                        localStorage.setItem('report', "Testing");
                        localStorage.setItem('PUR-OR','Approved From General Manager');
                        localStorage.setItem('ACC-PA','Approved From General Manager');
                        localStorage.setItem('ACC-PI','Approved From General Manager');
                        localStorage.setItem('HR-LAP','Completed');
                        localStorage.setItem('ACC-LO','Approved From General Manager');
                        localStorage.setItem('HR-ADS','Approved From General Manager');
                        localStorage.setItem('HR-EXP','Approved From General Manager');
                        localStorage.setItem('MAT-MR','Approved From General Manager');
                        localStorage.setItem('Visito','Completed');
                        localStorage.setItem('TIA-20','Completed');
                        localStorage.setItem('AGR-20','Approved From General Manager');
                    }
                    if(authData.username === "a.alzahrani@alhamdaan.com"){
                        localStorage.setItem('report', "API_GM");
                        localStorage.setItem('PUR-OR','Approved From General Manager'); // Purchase Order
                        localStorage.setItem('ACC-PA','Approved From General Manager'); // Payment Entry
                        localStorage.setItem('ACC-PI','Approved From General Manager'); // Purchase Invoice
                        localStorage.setItem('HR-LAP','Completed'); // Leave Application
                        localStorage.setItem('ACC-LO','Approved From General Manager'); // Loan Application
                        localStorage.setItem('Emp-GR','Approved From General Manager'); // Employee General Request Form
                        localStorage.setItem('HR-ADS','Approved From General Manager'); // Additional Salary
                        localStorage.setItem('HR-EXP','Approved From General Manager'); // Expense Claim
                        localStorage.setItem('MAT-MR','Approved From General Manager'); // Material Request
                        localStorage.setItem('Visito','Completed'); // Visitor Management
                        localStorage.setItem('TIA-20','Completed'); // Temporary Invoice Approval
                        localStorage.setItem('AGR-20','Approved From General Manager'); // CAR
                        localStorage.setItem('EMP-SE','Approved From General Manager'); // Resignation
                    }
                    if(authData.username === "zeshan@alhamdaan.com"){
                        localStorage.setItem('report', "API_TRANSPORT");
                        localStorage.setItem('PUR-OR','Approved From Logistic Department'); // Purchase Order
                        localStorage.setItem('HR-LAP','Approved From Leave Approver'); // Leave Application
                        localStorage.setItem('ACC-LO','Approved From Logistic Department'); // Loan Application
                        localStorage.setItem('Emp-GR','Approved From Logistic Department'); // Employee General Request Form
                        localStorage.setItem('HR-ADS','Approved From Logistic Department'); // Additional Salary
                        localStorage.setItem('HR-EXP','Approved From Logistic Department'); // Expense Claim
                        localStorage.setItem('MAT-MR','Approved From Logistic Department'); // Material Request
                        localStorage.setItem('Visito','Approved From Logistic Department'); // Visitor Management
                        localStorage.setItem('AGR-20','Approved From Logistic Department'); // CAR
                        localStorage.setItem('EMP-SE','Approved From Logistic Department'); // CAR
                    }
                    resolve(res);
                    router.replace('/')
                    window.location.reload();
                })
                .catch(error => {
                    // alert(JSON.stringify(error));
                    reject(error);
                })
        })
    },

    logout ({commit, dispatch}) {
        if(commit === dispatch){console.log("Test")}
        localStorage.clear();
        commit('setFullName', null);
        commit('setRoles', null);
        router.replace('/login')
    },

    getProfile ({commit, dispatch}) {
        if(commit === dispatch){console.log("Test")}
        return new Promise((resolve, reject) => {
            axios.get('http://system.alhamdaan.com/api/resource/User/'+localStorage.getItem('username'))
                .then(res => {
                    commit('setFullName', res.data.data.full_name);
                    commit('setRoles', res.data.data.roles);
                    resolve(res);
                })
                .catch(error => {
                    reject(error.response);
                })
        })
    },


};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
