<template>
  <div id="app">

    <div class="container-fluid">
      <div class="row" style="background-color: #2c3e50; color: white; padding-top: 15px;">
        <div class="col-md-10">

          <ul class="nav">
            <img src="./assets/logo.fw.png" style="height: 25px; margin: 5px 15px 0 0;"/>
            <li class="nav-item">

              <router-link to="/" class="nav-link">
                <i class="fas fa-project-diagram"></i>
                Approvals
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/dashboard" class="nav-link">
                <i class="fas fa-chart-line"></i>
                BI Dashboard
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/supply" class="nav-link">
                <i class="fas fa-retweet"></i>
                Supply Chain
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/hr" class="nav-link">
                <i class="fas fa-users"></i>
                Human Resource
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/finance" class="nav-link">
                <i class="fas fa-money-bill-alt"></i>
                Finance
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/sales" class="nav-link">
                <i class="fas fa-cash-register"></i>
                Sales
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/transport" class="nav-link">
                <i class="fas fa-truck-moving"></i>
                Logistics
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/transport" class="nav-link">
                <i class="fas fa-screwdriver"></i>
                Fleet
              </router-link>
            </li>

          </ul>
        </div>


        <div class="col text-right" style="margin-top: 8px;">Welcome {{$store.getters['profile/getFullName']}} || <span style="cursor: pointer;" @click="logout">Log Out</span></div>
      </div>

      <div class="row">

<!--        <div class="col-md-2 min-vh-100 left-bar text-left">-->
<!--          <h5>Workflow Management</h5>-->
<!--          <ul class="nav flex-column">-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/" class="nav-link">-->
<!--                <i class="fas fa-project-diagram"></i>-->
<!--                Manage Approvals-->
<!--              </router-link>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <br/>-->
<!--          <h5>Business Intelligence</h5>-->
<!--          <ul class="nav flex-column">-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/dashboard" class="nav-link">-->
<!--                <i class="fas fa-chart-line"></i>-->
<!--                BI Dashboard-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/supply" class="nav-link">-->
<!--                <i class="fas fa-retweet"></i>-->
<!--                Supply Chain-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/hr" class="nav-link">-->
<!--                <i class="fas fa-users"></i>-->
<!--                Human Resource-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/finance" class="nav-link">-->
<!--                <i class="fas fa-money-bill-alt"></i>-->
<!--                Finance-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/sales" class="nav-link">-->
<!--                <i class="fas fa-cash-register"></i>-->
<!--                Sales-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li class="nav-item">-->
<!--              <router-link to="/transport" class="nav-link">-->
<!--                <i class="fas fa-truck-moving"></i>-->
<!--                Logistics-->
<!--              </router-link>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->


        <div class="col-md-12 right-bar">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    // import axios from 'axios'
    // import router from './router'
    export default {
        name: 'App',
        components: {
        },

        mounted(){
            this.loadEnvironment();
        },
        methods: {
            loadEnvironment() {
                // Check if user is authenticated
                if(typeof localStorage.getItem('token') !== 'undefined' && localStorage.getItem('token') !== null) {
                    this.$store.dispatch('profile/getProfile')
                }
            },
            logout(){
                this.$store.dispatch('profile/logout')
            }
        },

        beforeDestroy: function(){
            clearInterval(this.interval);
        }

    }

</script>


<style>

</style>
