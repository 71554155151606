import axios from "axios";
import router from "../../router";

const namespaced = true;

const state = {
    comments: [],
    expense: {},
};

const getters = {
    getExpense (state) {
        return state.expense
    },
};

const mutations = {
    setExpense (state, payload) {
        state.expense = payload
    },
    setComments (state, payload) {
        state.comments = payload
    },
};

const actions = {
    fetchExpense ({commit, dispatch}, leaveID) {
        if(commit === dispatch || commit === router){console.log("Test")}
        return new Promise((resolve, reject) => {
            axios.get("http://system.alhamdaan.com/api/resource/Expense%20Claim/"+leaveID)
                .then(res => {
                    commit('setExpense', res.data);
                    resolve(res.data.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },

    fetchExpenseComments ({commit, dispatch}, leaveID) {
        if(commit === dispatch || commit === router){console.log("Test")}
        return new Promise((resolve, reject) => {
            axios.get('http://system.alhamdaan.com/api/resource/Comment?fields=["name","comment_type","reference_name","comment_email","content","modified"]&filters=[["comment_type", "=", "Comment"], ["reference_name", "=", "'+leaveID+'"]]')
                .then(res => {
                    commit('setComments', res.data);
                    resolve(res.data.data);
                })
                .catch(error => {
                    reject(error);
                })
        })
    },

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}
